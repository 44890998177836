import type { User } from '~/repository/modules'

export enum ActivityDescriptionTypes {
  updated = 'updated',
  created = 'created',
  note = 'note',
}

export interface Activity {
  id: string
  log_name: string
  description: ActivityDescriptionTypes | string
  model: string
  user?: User
  note?: string[] | {
    note: string
  }
  workflow_step_change?: string
  changes: {
    old?: Array<{
      [key: string]: string
    }>
    attributes?: Array<{
      [key: string]: string
    }>
  }
  created_at: string
}
